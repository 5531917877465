import { apiServer } from 'Api';
import { colors } from 'colors/colors';
import { GridColumn, Text } from 'components/box';
import Button from 'components/Button';
import { ButtonActive } from 'components/ButtonActive/ButtonActive';
import InputText from 'components/InputText/InputText';
import { ethers } from 'ethers';
import useBalance from 'hooks/useBalance';
import usePopup from 'hooks/usePopup';
// import Slippage from 'components/Transaction/Slippage';
import React, { useState } from 'react';
import { useAppDispatch } from 'state';
import { TRIGGER } from 'state/loading/action';
import styled from 'styled-components';

const BuySellModalWrapper = styled.div`
  min-width: 50rem;
  padding: 2rem;
  .detail {
    border: 1px solid white;
    border-radius: 1rem;
    padding: 1rem 4rem;
  }
  .type_btn {
    width: 100%;
    color: white;
    height: 4rem;
    border: 0;
    animation: none;
    border-radius: 0;
    font-size: 2rem;
  }
  .type_container {
    border: 0.2rem solid white;
  }
`;

interface Props {
  tone?: string;
  onClose?: () => any;
}

function OrderEnergyModal({ onClose }: Props) {
  const price = [0.4, 0.5, 0.8, 1.25];
  const [select, setPrice] = useState(0.4);
  const [amount, setAmout] = useState('');
  const [balance, refreshBalance] = useBalance();
  const pop = usePopup();
  const [type, setType] = useState('buy');
  const dispatch = useAppDispatch();

  async function buyEnergy() {
    const result = await apiServer.energy.buy(
      ethers.utils.parseEther(amount.toString()).toString(),
      select
    );

    if (result.success && result.data) {
      pop.success({
        title: 'Buy success',
        text: `You have bought ${amount} energy for ${parseFloat(
          (+amount * select).toString()
        ).toFixed(2)} BUSD!`,
      });
    } else {
      pop.error({
        title: 'Buy failed!',
        text: `Please try again`,
      });
    }
    refreshBalance();
    dispatch(TRIGGER());
    onClose();
  }

  async function sellEnergy() {
    const result = await apiServer.energy.sell(
      ethers.utils.parseEther(amount).toString(),
      select
    );

    if (result.success && result.data) {
      pop.success({
        title: 'Order placed',
        text: `Placed sell order ${amount} energy for ${parseFloat(
          (+amount * select).toString()
        ).toFixed(2)} BUSD!`,
      });
    } else {
      pop.error({
        title: 'Sell failed!',
        text: `Please try again`,
      });
    }
    refreshBalance();
    dispatch(TRIGGER());
    onClose();
  }

  const pendingSubmit = async () => {
    if (type === 'buy') {
      await buyEnergy();
    } else {
      await sellEnergy();
    }
  };

  return (
    <BuySellModalWrapper>
      <GridColumn gap="0.5rem">
        <GridColumn>
          <GridColumn
            className="type_container"
            style={{
              gridTemplateColumns: '1fr 1fr',
              gap: 0,
              borderColor: type === 'sell' ? '#faad14' : '#009ffd',
            }}
          >
            <ButtonActive
              tone={'blue'}
              active={type === 'buy'}
              onClick={() => {
                setAmout('');
                setType('buy');
              }}
              className="type_btn"
            >
              BUY
            </ButtonActive>
            <ButtonActive
              tone={'gold'}
              active={type === 'sell'}
              onClick={() => {
                setAmout('');
                setType('sell');
              }}
              className="type_btn"
            >
              SELL
            </ButtonActive>
          </GridColumn>
          <GridColumn style={{ gridTemplateColumns: '1fr 1fr 1fr 1fr' }}>
            {price.map((e) => (
              <ButtonActive
                key={`price-${e}`}
                tone={type === 'buy' ? 'blue' : 'gold'}
                active={select === e}
                onClick={() => {
                  setPrice(e);
                }}
                style={{ width: '10rem', height: '4rem', color: 'white' }}
              >
                {e}
              </ButtonActive>
            ))}
          </GridColumn>
          <Text size="lg">Enter amount to {type}</Text>
        </GridColumn>
        {type === 'buy' ? (
          <InputText
            value={amount}
            onChange={(e) => setAmout(e.target.value)}
            tone={type === 'buy' ? 'blue' : 'gold'}
            type="number"
            style={{ marginBottom: '2rem' }}
          />
        ) : (
          <InputText
            value={amount}
            onChange={(e) => setAmout(e.target.value)}
            max={parseFloat(
              ethers.utils.formatEther(balance?.token_2048E.balance ?? 0)
            )}
            tone={type === 'buy' ? 'blue' : 'gold'}
            type="number"
          />
        )}

        <Button
          disabled={
            type === 'sell'
              ? amount === '' ||
                Number(amount) <= 0 ||
                Number(amount) >
                  Number(
                    ethers.utils.formatEther(balance?.token_2048E.balance ?? 0)
                  )
              : amount === '' || Number(amount) <= 0
          }
          text={`PENDING ORDER ${type.toUpperCase()}`}
          tone={type === 'buy' ? 'blue' : 'gold'}
          onClick={pendingSubmit}
        />
      </GridColumn>
    </BuySellModalWrapper>
  );
}

export default OrderEnergyModal;
