import { apiServer } from 'Api';
import { colors } from 'colors/colors';
import { FlexRow, GridColumn, TextH2 } from 'components/box';
import PaginationStyled from 'components/PaginationStyled';
import React, { useEffect, useRef, useState } from 'react';
import { useLoading } from 'state/loading/hooks';
import styled from 'styled-components';
import { toneBg } from 'styling/fast';
import PendingItem from './PendingItem';

const StyledPendingList = styled.div`
  width: 100%;
  height: 70rem;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  padding-top: 1.5rem;
  position: relative;
  .topic_board {
    padding: 0.5rem 0;
    margin-top: 1rem;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 1rem;
  }
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(239, 112, 224, 0.5)
  );
  border-radius: 2rem;
  .hr_line {
    width: 100%;
    height: 0.2rem;
    margin: 1rem 0;
    background-color: ${colors.GRAY_LIGHT};
  }
  .header_leadder {
    background: ${toneBg('pink')};
    font-size: 2rem;
    font-weight: 600;
    color: white;
    align-self: flex-start;
    padding: 1rem 3rem;
    border-radius: 0 0 3rem 0;
    transform: translateX(-2rem);
  }
  .type_button_container {
    font-size: 2rem;
    font-weight: 600;
    color: white;
    .active_btn {
      display: flex;
      border-radius: 0.5rem;
      justify-content: center;
      align-items: center;
      padding: 0 1rem;
      font-size: 1.6rem;
      margin: 0 1rem;
      height: 4rem;
      cursor: pointer;
      transition: all 0.5 ease-in-out;
    }
  }
`;

function PendingList() {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const row = 50;
  const table = useRef(null);
  const trigger = useLoading();

  const fetchData = async () => {
    setData([]);

    // getTotal
    const result = await apiServer.energy.getPendingList(row, (page - 1) * row);

    if (result.success && result.data) {
      setData(result.data.data);
      setTotal(result.data.total);
    }
    if (table.current) {
      if (table.current.scrollTop) {
        table.current.scrollTop = 0;
      }
    }
  };

  const onChangePage = async (e) => {
    setPage(e);
  };

  useEffect(() => {
    fetchData();
  }, [trigger, page]);

  return (
    <StyledPendingList>
      <div className="header_leadder">Pending Orders List</div>
      <div className="topic_board">
        <TextH2 align="center" size="1.6rem" weight={'600'}>
          Time
        </TextH2>
        <TextH2 align="center" size="1.6rem" weight={'600'}>
          Order
        </TextH2>
        <TextH2 align="center" size="1.6rem" weight={'600'}>
          Amount
        </TextH2>
        <TextH2 align="center" size="1.6rem" weight={'600'}>
          Price
        </TextH2>
        <TextH2 align="center" size="1.6rem" weight={'600'}>
          Total
        </TextH2>
        <TextH2 align="center" size="1.6rem" weight={'600'}>
          Action
        </TextH2>
      </div>
      <GridColumn ref={table} gap="0.8rem">
        {data.map((e, index) => (
          <PendingItem key={e._id} data={e} />
        ))}
      </GridColumn>
      <FlexRow style={{ justifyContent: 'flex-end', marginTop: '1rem' }}>
        <PaginationStyled
          row={row}
          onChange={onChangePage}
          page={page}
          total={total}
        />
      </FlexRow>
    </StyledPendingList>
  );
}

export default PendingList;
