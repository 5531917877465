import { colors } from 'colors/colors';
import { TextH2 } from 'components/box';
import { ethers } from 'ethers';
import React from 'react';
import styled from 'styled-components';
import { flexAlign } from 'styling/fast';

const OrderLogsItemWrapper = styled.div`
  width: 100%;
  background-color: rgba(0, 0, 0, 1);
  border-radius: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 1rem;
  padding: 1rem 0;
  .ranking {
    display: flex;
    ${flexAlign}
    place-self: center;
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
    border: 0.2rem solid white;
  }
  .score {
    width: 80%;
    height: auto;
    border-radius: 1rem;
  }
`;

function OrderLogsItem({ data }) {
  const getTimeParse = () => {
    return new Date(data.matchedAt).toLocaleString();
  };

  const amount = data.isSell
    ? ethers.utils.formatEther(data.sellAmount)
    : ethers.utils.formatEther(data.buyAmount);

  const price = data.isSell ? 1 / data.price : data.price;

  const totalPrice = (+amount * Number(price)).toFixed(2);
  return (
    <OrderLogsItemWrapper>
      <TextH2 style={{ textAlign: 'center', fontSize: '1.4rem' }}>
        {getTimeParse()}
      </TextH2>
      <TextH2
        style={{ textAlign: 'center', fontSize: '1.4rem' }}
        color={data.isSell ? colors.RED_PROFIT : colors.GLEEN_PROFIT}
        weight={'500'}
      >
        {data.isSell ? 'SELL' : 'BUY'}
      </TextH2>
      <TextH2 style={{ textAlign: 'center', fontSize: '1.6rem' }}>
        {amount}
      </TextH2>
      <TextH2 style={{ textAlign: 'center', fontSize: '1.6rem' }}>
        {Number(price).toFixed(2)} BUSD
      </TextH2>
      <TextH2
        style={{ textAlign: 'center', fontSize: '1.6rem' }}
        color={data.isSell ? colors.RED_PROFIT : colors.GLEEN_PROFIT}
      >
        {totalPrice} BUSD
      </TextH2>
    </OrderLogsItemWrapper>
  );
}

export default OrderLogsItem;
