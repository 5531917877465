import Endpoint from '../Endpoint';

enum APIS {
  PATH = `/energy`,
}

export default class EnergyEndpointed extends Endpoint {
  async generate(tokenId, amount) {
    return await this.axiosWrapper
      .post(`${APIS.PATH}/generate`, { tokenId, amount })
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }

  async setPrice(tokenId, energyPrice) {
    return await this.axiosWrapper
      .post(`${APIS.PATH}/set_price`, { tokenId, energyPrice })
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }

  async sell(amount, price) {
    return await this.axiosWrapper
      .post(`${APIS.PATH}/sell`, { amount, price })
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }

  async buy(amount, price) {
    return await this.axiosWrapper
      .post(`${APIS.PATH}/buy`, { amount, price })
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }

  async getHistoryLogs(limit = 20, skip = 0) {
    return await this.axiosWrapper
      .get(`${APIS.PATH}/order/history`, {
        params: {
          limit,
          skip,
        },
      })
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }

  async getPendingList(limit = 20, skip = 0) {
    return await this.axiosWrapper
      .get(`${APIS.PATH}/order/pending`, {
        params: {
          limit,
          skip,
        },
      })
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }

  async cancelPending(id: any) {
    return await this.axiosWrapper
      .delete(`${APIS.PATH}/order/${id}`)
      .then((res) => this.axiosWrapper.interceptor<any>(res))
      .catch((err) => this.axiosWrapper.interceptor<any>(err));
  }
}
