import { apiServer } from 'Api';
import { colors } from 'colors/colors';
import { FlexRow, TextH2 } from 'components/box';
import Button from 'components/Button';
import { ethers } from 'ethers';
import useBalance from 'hooks/useBalance';
import useConfirm from 'hooks/useConfirm';
import usePopup from 'hooks/usePopup';
import React from 'react';
import { AiFillDelete } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { TRIGGER } from 'state/loading/action';
import styled from 'styled-components';
import { flexAlign } from 'styling/fast';

const PendingItemWrapper = styled.div`
  width: 100%;
  background-color: rgba(0, 0, 0, 1);
  border-radius: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  place-content: center;
  align-items: center;
  grid-column-gap: 1rem;
  padding: 1rem 0;
  .ranking {
    display: flex;
    ${flexAlign}
    place-self: center;
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
    border: 0.2rem solid white;
  }
  .score {
    width: 80%;
    height: auto;
    border-radius: 1rem;
  }
  .cancel_btn {
    width: 14rem;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    .icon {
      margin-right: 0.4rem;
    }
  }
`;

function PendingItem({ data }) {
  const popup = usePopup();
  const { isConfirmed } = useConfirm();
  const dispatch = useDispatch();
  const [_, refreshBalance] = useBalance();
  const getTimeParse = () => {
    return new Date(data.createdAt).toLocaleString();
  };

  const amount = data.isSell
    ? ethers.utils.formatEther(data.sellAmount)
    : ethers.utils.formatEther(data.buyAmount);

  const price = data.isSell ? data.price : 1 / data.price;

  let totalPrice = (+amount * Number(price)).toFixed(2);

  const cancelPending = async () => {
    const confirmed = await isConfirmed({
      text: 'Confirm to cancel this orider',
    });
    if (!confirmed.confirm) return;
    const result = await apiServer.energy.cancelPending(data.id);
    if (result.success) {
      popup.success({
        title: 'Cancel pending order',
        text: 'Cancel pending successfull!',
      });
    } else {
      popup.error({
        title: 'Cancel pending order',
        text: 'Cancel pending failed',
      });
    }
    refreshBalance();

    dispatch(TRIGGER());
  };
  return (
    <PendingItemWrapper>
      <TextH2 style={{ textAlign: 'center', fontSize: '1.2rem' }}>
        {getTimeParse()}
      </TextH2>
      <TextH2
        style={{ textAlign: 'center', fontSize: '1.4rem' }}
        color={data.isSell ? colors.RED_PROFIT : colors.GLEEN_PROFIT}
        weight={'500'}
      >
        {data.isSell ? 'SELL' : 'BUY'}
      </TextH2>
      <TextH2 style={{ textAlign: 'center', fontSize: '1.6rem' }}>
        {amount}
      </TextH2>
      <TextH2 style={{ textAlign: 'center', fontSize: '1.6rem' }}>
        {Number(price).toFixed(2)} BUSD
      </TextH2>
      <TextH2
        style={{ textAlign: 'center', fontSize: '1.6rem' }}
        color={data.isSell ? colors.RED_PROFIT : colors.GLEEN_PROFIT}
      >
        {totalPrice} BUSD
      </TextH2>
      <FlexRow>
        <Button
          className="cancel_btn"
          tone="red"
          disabled={false}
          onClick={cancelPending}
        >
          <FlexRow>
            <AiFillDelete className="icon" />
            <span>CANCEL</span>
          </FlexRow>
        </Button>
      </FlexRow>
    </PendingItemWrapper>
  );
}

export default PendingItem;
