import { colors } from 'colors/colors';
import { FlexColumn, FlexRow, TextH2 } from 'components/box';
import { ButtonActive } from 'components/ButtonActive/ButtonActive';
import React, { useState } from 'react';
import styled from 'styled-components';
import HistoryLogs from './HistoryLogs';
import PendingList from './PendingList';

const StyledOrderLogs = styled.div`
  width: 80%;
  margin: 0 auto;
  position: relative;
  .type_container {
    width: auto;
    height: auto;
    padding: 1rem 2rem;
    margin-left: 2rem;
    position: absolute;
    right: 0;
    z-index: 1;
  }
  .active_btn-active {
    background-color: #d40deb;
  }
  .active_btn-dis {
    background-color: #dc74e8;
    :hover {
      background-color: #d40deb;
    }
  }
  .active_btn {
    display: flex;
    border-radius: 0.5rem;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    font-size: 1.6rem;
    margin: 0 1rem;
    height: 4rem;
    cursor: pointer;
    transition: all 0.5 ease-in-out;
    color: white;
    font-weight: 600;
  }
`;

function OrderLogs() {
  const [pending, setPending] = useState(true);
  return (
    <StyledOrderLogs>
      <FlexRow className="type_container">
        <div
          className={`active_btn ${
            pending ? 'active_btn-active' : 'active_btn-dis'
          }`}
          onClick={() => setPending(true)}
        >
          PENDING ORDERS
        </div>

        <div
          className={`active_btn ${
            !pending ? 'active_btn-active' : 'active_btn-dis'
          }`}
          onClick={() => setPending(false)}
        >
          HISTORY LOGS
        </div>
      </FlexRow>
      <div className="hr_line" />
      {!pending ? <HistoryLogs /> : <PendingList />}
    </StyledOrderLogs>
  );
}

export default OrderLogs;
