import { Pagination } from 'antd';
import { colors } from 'colors/colors';
import React from 'react';
import styled from 'styled-components';

const PaginationStyledWrapper = styled.div`
  .ant-pagination {
    * {
      color: white;
    }
  }
  .ant-pagination-item {
    min-width: auto;
    width: 4rem;
    height: 3.2rem;
    font-size: 1.6rem;
    padding-top: 0.5rem;
    background-color: ${colors.PINK1};
    border: 0;
    border-radius: 0.5rem;
    :hover {
      background-color: ${colors.PURPLE_LIGHT};
      a {
        color: white;
      }
    }
  }
  .ant-pagination-item-active {
    width: 4rem;
    height: 3.2rem;
    background-color: ${colors.PURPLE_LIGHT};
  }
  .ant-pagination-item-link {
    background-color: ${colors.BLUE};
    border: 0;
    border-radius: 0.5rem;
  }
  .ant-pagination-options {
    display: none;
  }
  .ant-pagination-item-ellipsis {
    color: white;
    svg {
      color: white;
    }
  }
  .ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-ellipsis {
    color: white;
    display: flex;
    padding-top: 1.5rem;
    padding-left: 0.5rem;
  }
  .ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-ellipsis {
    color: white;
    display: flex;
    padding-top: 1.5rem;
    padding-left: 0.5rem;
  }
  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    min-width: auto;
    width: 3.2rem;
    height: 3.2rem;
    padding: 0;
    .anticon {
      display: flex;
      justify-content: center;
    }
    span {
      padding: 0;
      svg {
        width: 1.4rem;
      }
    }
  }
`;

function PaginationStyled({ page, onChange, total, row }) {
  console.log(page);

  if ((page = 1 && total <= row)) return null;

  return (
    <PaginationStyledWrapper>
      <Pagination
        defaultCurrent={1}
        pageSize={row}
        onChange={onChange}
        total={total}
      />
    </PaginationStyledWrapper>
  );
}

export default PaginationStyled;
